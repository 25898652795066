<template>
	<div class="box">
		<div class="row">
			<div class="col-8">
				<template v-if="!loading">
					<Reorganize ref="reorganize" :invoicedetails_id="invoicedetails_ids" :bons="bons" :processing.sync="processing"></Reorganize>

					<div class="col-8">
						<b-button block pill variant="primary" @click="onSubmitReorganize"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.sauvegarder") }}</b-button>
					</div>
				</template>
				<template v-else>
					<LoadingSpinner class="col-12" />
				</template>
			</div>
			<div class="col-4">
				<template v-if="change_model_ready">
					<div class="form-group">
						<label for="">{{ $t('invoice.choisir_un_modele_specifique') }}</label>
						<e-select
							v-model="model_choosen"
							id="search_compte"
							track-by="model_label"
							label="model_label"
							:placeholder="getTrad('invoice.choisir_un_modele_specifique')"
							:selectedLabel="getTrad('global.selected_label')"
							:options="model"
							:searchable="true"
							:allow-empty="false"
							:loading="isLoadingModel"
							:show-labels="false"
						>
						</e-select>
					</div>
					<b-button block pill variant="primary" @click="previsualiser"><font-awesome-icon v-if="processing_preview" :icon="['fas', 'spinner']" pulse /> {{ $t("action.previsualiser") }}</b-button>
					<b-button block pill variant="primary" @click="addInvoiceForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.facturer") }}</b-button>

				</template>
			</div>
		</div>


		<div class="row mt-5">
			<div class="col">
				<iframe v-if="base64 != null" :src="'data:application/pdf;base64,' + base64" height="1000px" width="100%" style="position:relative"></iframe>
			</div>
		</div>


		<b-modal ref="modal" hide-footer>
			<template v-slot:modal-title>
				{{ $t("action.facturer") }}
			</template>

			<div v-if="!modal_loaded">
				<LoadingSpinner class="col-12" />
			</div>

			<div v-if="modal_loaded">
				<form @submit.prevent="checkForm" class="mb-4">
					<div class="row">
						<div class="col-12" v-if="form_message !== ''">
							<ErrorAlert :messageI18n="form_message" />
						</div>

						<div class="col-12">
							<div class="form-group">
								<label for="date">{{ $t('invoice.definir_date_facturation') }}</label>
								<e-datepicker v-model="date"></e-datepicker>
								<small class="form-text text-muted">{{ $t('invoice.definir_date_facturation_text') }}</small>
							</div>

							<div class="form-group">
								<b-form-checkbox
									v-model="send_email"
									:unchecked-value="false"
								>
								{{ $t('invoice.send_invoice_by_email') }}
								</b-form-checkbox>
							</div>
						</div>
					</div>

					<div v-for="(invoice, invoice_key) in params_to_send.invoices" :key="'invoice_'+invoice_key">
						<div class="row">
							<div class="col">
								<div class="form-group">
									<label for="duedate_date">{{ $t('invoice.definir_date_echeance') }} {{ invoice.group_by_author_tiers }}</label><br/>
									<label>{{ $t('invoice.invoice_balance') }} :</label><span> {{ amount[invoice.author_id + '_' + invoice.tiers_id].invoice_ttc_formatted }} </span>

									<template v-for="(duedate, duedate_key) in invoice.duedates" >
										<div class="row" :key="'duedate_'+duedate_key">
											<div class="col">
												<e-datepicker v-model="duedate.date" :class="{ 'is-invalid': errors && errors.indexOf('Erreur date duedate ' + duedate.date) > -1 }"></e-datepicker>
											</div>
											<div class="col" v-show="many_duedate">
												<input class="form-control" type="text" v-model="duedate.amount" :class="{ 'is-invalid': errors && errors.indexOf('Erreur somme duedate ' + duedate.amount) > -1 }">
											</div>
										</div>
									</template>
									<a href="#" @click.prevent="add_duedate(invoice)" v-show="many_duedate">{{ $t('global.ajouter') }}</a>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col" v-if="change_model_ready_popup">
								<div class="form-group">
									<label for="">{{ $t('invoice.choisir_un_modele_specifique') }}</label>
									<e-select
										v-model="model_choosen"
										id="search_compte"
										track-by="model_label"
										label="model_label"
										:placeholder="getTrad('invoice.choisir_un_modele_specifique')"
										:selectedLabel="getTrad('global.selected_label')"
										:options="model"
										:searchable="true"
										:allow-empty="false"
										:loading="isLoadingModel"
										:show-labels="false"
									>
									</e-select>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col" v-if="change_rib_ready">
								<div class="form-group">
									<label for="">{{ $t('invoice.choisir_un_rib_specifique') }}</label>
									<e-select
										v-model="invoice.rib"
										id="search_compte"
										track-by="checkdepositconfiguration_id"
										label="checkdepositconfiguration_iban"
										:placeholder="getTrad('invoice.choisir_un_rib_specifique')"
										:selectedLabel="getTrad('global.selected_label')"
										:options="ribs[invoice.author_id]"
										:searchable="true"
										:allow-empty="true"
										:loading="isLoadingRib"
										:show-labels="false"
									>
										<template slot="singleLabel" slot-scope="{ option }">{{ option.checkdepositconfiguration_libelle }} {{ option.checkdepositconfiguration_iban }}</template>
										<template slot="option" slot-scope="{ option }">{{ option.checkdepositconfiguration_libelle }} {{ option.checkdepositconfiguration_iban }}</template>
									</e-select>
								</div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col">
							<b-form-checkbox
								v-model="many_duedate"
								>
								{{ $t('invoice.multi_echeance') }}
							</b-form-checkbox>
						</div>
					</div>

					<div class="row">
						<div class="col">
							<b-form-checkbox
								v-model="change_model"
								>
								{{ $t('invoice.changer_modele_facture') }}
							</b-form-checkbox>
						</div>
					</div>
					<div class="row">
						<div class="col">
							<b-form-checkbox
								v-model="change_rib"
								>
								{{ $t('invoice.changer_rib_default') }}
							</b-form-checkbox>
						</div>
					</div>
				</form>

				<div class="col-8 m-auto">
					<b-button block pill variant="primary" @click="checkForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.ajouter") }}</b-button>
				</div>
			</div>
		</b-modal>
	</div>
</template>


<script type="text/javascript">
	import Vue from 'vue'
	import { EventBus } from 'EventBus'


	import TableAction from "@/mixins/TableAction.js"
	import Invoice from "@/mixins/Invoice.js"
	import Accounting from "@/mixins/Accounting.js"

	import Navigation from "@/mixins/Navigation.js"
	import Model from "@/mixins/Model.js"
	import Config from "@/mixins/Config.js"
	import Tiers from "@/mixins/Tiers.js"
	import _uniq from 'lodash/uniq'
	import ContractCleaner from '@/assets/js/cache/cleaners/ContractCleaner'
	import Contract from "@/mixins/Contract.js"

	export default {
		name: "Detail",
		mixins: [Config, TableAction, Invoice, Navigation, Accounting, Model, Tiers, Contract],
		props: {
			
		},
		data () {
			return {
				modal_loaded: false,
				loading: false,
				form_message: '',
				send_email: false,
				date: new Date(),
				many_duedate: false,
				params_to_send : [],
				errors : [],
				config_duedate : [],
				amount : {},
				invoicedetails_ids: null,
				change_model: true,
				isLoadingModel: false,
				isLoadingRib: false,
				copy_duedate: null,
				change_rib: false,
				change_model_ready: false,
				change_model_ready_popup: false,
				change_rib_ready: false,
				processing: false,
				model_choosen: null,
				ribs: [],
				model: [],
				base64: null,
				processing_preview: false,
				bons: [],
				events_tab: {
					
				},
				tiers_tab: []
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				//Split car je passe un tableau au composant reorganize
				this.invoicedetails_ids = this.$route.params.invoicedetails_ids.split(',')
                await this.loadDetails()
				await this.load_model()
			},
			async loadDetails() {
				this.loading = true
				this.has_doublons = false
                this.bons = await this.loadAllBons({
					type: "Invoice",
                	id: this.invoicedetails_ids.join(',')
				})
                this.loading = false
            },
			async load_model(){
				this.change_model_ready = false
				this.change_model_ready_popup = false
				this.isLoadingModel = true
				if(this.bons.length > 0) {
					let result = await this.loadAccountingPlanByEntity(this.bons[0].invoicedetails_author)

					let models = await this.loadAllModel(result.accountingplan_id, ['accounting_plan'])
					models = models.filter(model => (model.type.modeltype_template === 'facture'))

					this.model = models
				}

				this.isLoadingModel = false
				this.change_model_ready = true
				this.change_model_ready_popup = true
			},
			async onSubmitReorganize() {
				if(!this.processing)
				{
					this.processing = true
					let invoice_details = await this.$refs.reorganize.checkForm()
					if(invoice_details)
					{
						this.successToast()
					}
					this.processing = false
					this.ready = true
				}
			},
			async previsualiser() {
				if(!this.processing_preview)
				{
					this.processing_preview = true
					let infos = []
					infos.push({
						"author_id": this.bons[0].invoicedetails_author,
						"tiers_id": this.bons[0].invoicedetails_tiers,
					})

					let params_to_send = {
						"invoices": infos,
						"invoicedetails_ids": this.invoicedetails_ids.join(','),
						"model": this.model_choosen.model_id
					}

					this.base64 = await this.previewInvoice(params_to_send, false)

					this.processing_preview = false
				}
			},

			async addInvoiceForm()
			{
				this.change_model_ready_popup = true
				this.change_model = true
				this.modal_loaded = false
				this.$refs['modal'].show()

				this.details_selected = this.bons.filter(bons => this.invoicedetails_ids.join(",").includes(bons.invoicedetails_id))

				const splited_invoice_details = this.invoicedetails_ids
				const all_bons = this.bons
				const infos = []
                const tiers = []
                this.tiers_tab = []
                const authors = []
				this.ribs_from_stallions = []
                
				for (var i = 0; i < splited_invoice_details.length; i++) {
                    const bons_selected = this.bons.filter(all_bons => all_bons.invoicedetails_id == splited_invoice_details[i])[0]

					if(authors.indexOf(bons_selected.invoicedetails_author) === -1 || tiers.indexOf(bons_selected.invoicedetails_tiers) === -1) {
						infos.push({
							"author_id": bons_selected.invoicedetails_author,
							"tiers_id": bons_selected.invoicedetails_tiers,
							"group_by_author_tiers": bons_selected.group_by_author_tiers
						})

						tiers.push(bons_selected.invoicedetails_tiers)
						this.tiers_tab.push(bons_selected.tiers)
						authors.push(bons_selected.invoicedetails_author)
					}

					if(bons_selected.contract && bons_selected.contract.contract_config && bons_selected.contract.contract_config.contractconfig_configuration != null) {
						if(!this.ribs_from_stallions[bons_selected.group_by_author_tiers]) {
							this.ribs_from_stallions[bons_selected.group_by_author_tiers] = []
						}

						this.ribs_from_stallions[bons_selected.group_by_author_tiers].push(bons_selected.contract.contract_config.contractconfig_configuration)
					}
					else{
						this.use_rib_stallions = false
					}
				}

				this.params_to_send = {
					"invoices": infos,
					"invoicedetails_ids": this.invoicedetails_ids.join(",")
                }

                const tiers_ids = this.tiers_tab.map(tier => tier.tiers_id)
				this.config_duedate = await this.getConfigDuedate(tiers_ids)

				const amount = await this.calculateInvoice(this.params_to_send)
				this.amount = await this.formatAmount(amount)

				await this.add_duedate()

				this.modal_loaded = true
			},

			async formatAmount(amount) {
				return amount
			},

			async getNewDate(tiers_id, date) {
				return await this.getNextDateDuedate(tiers_id, date)
			},

			async calculDuedateDate(use_prev = false){

				this.new_invoices_tab = []

				for (let i = 0; i < this.params_to_send.invoices.length; i++) {
					let author_id = this.params_to_send.invoices[i].author_id
					let tiers_id = this.params_to_send.invoices[i].tiers_id
					let key = author_id + "_" + tiers_id

					let current_date = this.date

					//Je regarde si je dois prendre la date précédente pour calculer les échéances à venir
					if(use_prev)
					{
						if(this.params_to_send.invoices[i].duedates != undefined)
						{
							let all_amount = parseInt(this.amount[key].invoice_ttc.toString().toFloat() * 100)

							//Je parcours les echeances pour calculer la date
							for (let j = 0; j < this.params_to_send.invoices[i].duedates.length; j++)
							{

								let date = null
								let current = null
								let amount_float = 0

								if(j == 0)
								{
									if(this.params_to_send.invoices[i].duedates[j] != undefined)
									{
										current = this.params_to_send.invoices[i].duedates[j].date
									}
									else
									{
										current = this.calculNextDateDuedate(this.config_duedate[tiers_id], current_date)
									}
								}
								else
								{
									if(this.params_to_send.invoices[i].duedates[j].date != undefined)
									{
										current = this.params_to_send.invoices[i].duedates[j].date
									}
									else
									{
										current = this.calculNextDateDuedate(this.config_duedate[tiers_id], this.params_to_send.invoices[i].duedates[j - 1].date)
									}
								}

								//Si j'ai une valeur de renseignée, je la reprend
								if(this.params_to_send.invoices[i].duedates[j].amount != undefined)
								{
									amount_float = parseInt(this.params_to_send.invoices[i].duedates[j].amount.toString().toFloat() * 100)
								}

								if((j + 1) == this.params_to_send.invoices[i].duedates.length)
								{
									amount_float = all_amount
								}

								this.params_to_send.invoices[i].duedates[j] = {
									'date': current,
									'amount': (amount_float / 100).toString().toFloat(),
									'amount_float': amount_float,
									'total_invoice': parseInt(this.amount[key].invoice_ttc.toString().toFloat() * 100)
								}
								all_amount -= amount_float

								if(all_amount < 0)
								{
									all_amount = 0
								}
							}
						}
					}
					else
					{
						let date = this.calculNextDateDuedate(this.config_duedate[tiers_id], current_date)

						let current = new Date(date);
						let amount_float = parseInt(this.amount[key].invoice_ttc.toString().toFloat() * 100)
						this.params_to_send.invoices[i].duedates = [{
							'date': current,
							'amount': this.amount[key].invoice_ttc,
							'amount_float': amount_float,
							'total_invoice': parseInt(this.amount[key].invoice_ttc.toString().toFloat() * 100)
						}]
					}
					this.new_invoices_tab.push(this.params_to_send.invoices[i])
				}

				this.$set(this.params_to_send, 'invoices', this.new_invoices_tab)
			},


			async add_duedate(tab_invoice){
				if(tab_invoice != undefined)
				{
					for (let i = 0; i < this.params_to_send.invoices.length; i++)
					{
						//J'ajoute une date là ou j'ai choisi de cliquer sur ajouter
						if(this.params_to_send.invoices[i].group_by_author_tiers == tab_invoice.group_by_author_tiers)
						{
							let current = new Date(this.date);
							const key = this.params_to_send.invoices[i].author_id + "_" + this.params_to_send.invoices[i].tiers_id

							this.params_to_send.invoices[i].duedates.push({
								'date': undefined,
								'amount': "0",
								'amount_float': 0,
								'total_invoice': parseInt(this.amount[key].invoice_ttc.toString().toFloat() * 100)
							})
						}
					}
					this.calculDuedateDate(true)
				}
				else
				{
					this.calculDuedateDate()
				}

			},
			reset_copy_duedate(){

				this.copy_duedate = null
			},
			async load_rib(){
				this.change_rib_ready = false
				for (let i = 0; i < this.params_to_send.invoices.length; i++) {
					let result = await this.loadAccountingPlanByEntity(this.params_to_send.invoices[i].author_id)

					let ribs = await this.getCheckDepositConfigListe(result.accountingplan_id)

					this.ribs[this.params_to_send.invoices[i].author_id] = ribs
				}

				this.change_rib_ready = true
			},
			checkForm: async function(){

				if(!this.processing)
				{
					this.processing = true
					this.errors = []

					if(this.date == null)
					{
						this.errors.push("Erreur date null")
					}

					if(!Date.parse(this.date))
					{
						this.errors.push("Erreur wrong date")
					}

					//Je vais regarder que mes dates d'échéances soient supérieurs ou égales à ma date de facturation, et regarder le montant
					for (let i = 0; i < this.params_to_send.invoices.length; i++)
					{
						let somme = 0

						//Si j'ai qu'un seul rib etalon de selectionné pour une entité/tiers, et que je n'ai pas choisi manuellement mon rib
						if(this.ribs_from_stallions[this.params_to_send.invoices[i].group_by_author_tiers] != undefined
							&& _uniq(this.ribs_from_stallions[this.params_to_send.invoices[i].group_by_author_tiers]).length == 1
							&& (this.params_to_send.invoices[i].rib == undefined || this.params_to_send.invoices[i].rib == null)
							&& this.use_rib_stallions
						) {
							this.params_to_send.invoices[i].rib = {
								"checkdepositconfiguration_id": this.ribs_from_stallions[this.params_to_send.invoices[i].group_by_author_tiers][0]
							}
						}

						for (let j = 0; j < this.params_to_send.invoices[i].duedates.length; j++)
						{
							if(j == 0)
							{
								somme = this.params_to_send.invoices[i].duedates[j].total_invoice
							}

							if(!Date.parse(this.params_to_send.invoices[i].duedates[j].date) || this.params_to_send.invoices[i].duedates[j].date < this.date)
							{
								this.errors.push("Erreur date duedate " + this.params_to_send.invoices[i].duedates[j].date)
							}

							somme -= parseInt(this.params_to_send.invoices[i].duedates[j].amount.toString().toFloat() * 100)
						}
						if(somme != 0)
						{
							this.errors.push("Erreur somme duedate " + this.params_to_send.invoices[i].duedates[0].amount)
						}
                    }

					if(this.errors.length == 0)
					{
						this.params_to_send.date = this.date;
						this.params_to_send.send_mail = this.send_email;

						const result = await this.createInvoice(this.params_to_send);
						if(result) {
							const details_contract = this.details_selected.filter(details => details.invoicedetails_contract)
							const contract_ids = _uniq(details_contract.map(details => details.invoicedetails_contract))
		                    await this.$sync.force(true)
		                	ContractCleaner.inst().onMutation(contract_ids, ['articles'])
		                	this.updateContract(contract_ids)
							this.$refs['modal'].hide()
							this.$router.push({name: 'invoiceList'})
						} else {
							this.form_message = "error.LEP"
						}
					}
					else
					{
						this.form_message = "formulaire.erreur_champs_non_remplis"
					}

					this.processing = false
				}

			},
		},
		computed: {
			
		},
		watch: {
			date(val, prev)
			{
				if(this.recalcul_duedate)
				{
					this.add_duedate()
					this.reset_copy_duedate()
				}
			},
			'params_to_send.invoices':{
				handler(val){

					if(JSON.stringify(val).indexOf('duedates') > -1 && this.copy_duedate == null && this.recalcul_duedate)
					{
						this.copy_duedate = JSON.stringify(val)
					}

					if(this.copy_duedate != null)
					{
						if(JSON.stringify(val) != this.copy_duedate)
						{
							//Je passe une variable pour ne pas que cela recalcul les dates d'échéances 
							this.recalcul_duedate = false
						}
					}

				},
				deep: true
			},
			many_duedate(val, prev){

				if(val === false)
				{
					this.add_duedate()
					this.reset_copy_duedate()
				}

			},
			change_model(val, prev){
				if(val === true)
				{
					this.load_model()
				}
				else{
					this.change_model_ready_popup = false
				}

			},
			change_rib(val, prev){
				if(val === true)
				{
					this.load_rib()
				}
				else{
					this.change_rib_ready = false
				}

			},
			send_email(val, prev){
				if(val === true) {

					// On vérifie que les tiers sont notifiables
					const tiers_unotifiable = this.tiers_tab.filter(tier => {
						return !tier.tiers_mail
					})

					if(tiers_unotifiable.length === 1) {
						this.infoToast("invoice.tier_non_notifiable", [tiers_unotifiable[0].tiers_rs])
					}
					else if(tiers_unotifiable.length > 1) {
						const delimiter = ', '
						let tiers_rs = tiers_unotifiable.map(tier => tier.tiers_rs).join(delimiter)
						const index = tiers_rs.lastIndexOf(delimiter)
						tiers_rs = tiers_rs.substring(0, index) + ' '+this.getTrad('global.et')+' ' + tiers_rs.substring(index + delimiter.length)

						this.infoToast("invoice.tiers_non_notifiable", [tiers_rs])
					}

				}
			},
		},
		components: {
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
			LigneVierge: () => import('@/components/Invoice/LigneVierge'),
			Reorganize: () => import('@/components/Invoice/Reorganize'),
		}
	}
</script>
